<template>
  <div
    class="my-5 py-5"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/background-login.jpg') + ')'
    }"
    id="resetPasswordArea"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="auth-wrapper">
          <div class="card overflow-hidden" style="background: transparent">
            <div class="card-title card-header px-0">
              <img src="@/assets/logo-dark.png" height="50" />
            </div>

            <div class="card-body bg-white">
              <b-alert v-model="isAuthError" variant="danger" dismissible>
                {{ authError }}
              </b-alert>

              <b-form @submit.prevent="save">
                <b-form-group
                  id="password-input-group"
                  label="Nova senha"
                  label-for="password-input"
                >
                  <b-form-input
                    id="password-input"
                    v-model="password"
                    type="password"
                    placeholder="Digite a senha"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </b-form-group>

                <b-form-group
                  id="confirm-password-input-group"
                  label="Repita a Senha"
                  label-for="confirm-password-input"
                >
                  <b-form-input
                    id="confirm-password-input"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Digite a senha"
                    :class="{
                      'is-invalid': submitted && $v.confirmPassword.$error
                    }"
                  />

                  <div
                    v-if="submitted && !$v.confirmPassword.required"
                    class="invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </b-form-group>

                <div class="mt-3 text-center">
                  <b-button
                    pill
                    type="submit"
                    variant="primary"
                    class="font-weight-bold"
                    style="padding: 12px 62px"
                    v-if="!loading"
                  >
                    Salvar
                  </b-button>

                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    class="mt-1"
                    v-if="loading"
                  />
                </div>
              </b-form>
            </div>
          </div>
          <div class="text-center">
            <p>
              Não tem conta?
              <a
                target="_blank"
                href="http://cadastro.unico.segasp-univalores.com.br/"
              >
                Quer ser um CORRETOR SEGASP
              </a>
            </p>
            <p>&copy; {{ actualYear }} - SEGASP Univalores</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('auth');
export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      password: '',
      confirmPassword: '',
      submitted: false,
      authError: null,
      loading: false,
      isAuthError: false,
      passwordRecovery: null
    };
  },
  mixins: [validationMixin],
  validations: {
    password: { required },
    confirmPassword: { required }
  },
  methods: {
    ...mapActions(['resetPasswordGet', 'resetPassword']),
    save() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;

      this.resetPassword({
        password: this.password,
        confirmPassword: this.confirmPassword,
        token: this.passwordRecovery.token
      })
        .then(() => {
          this.$router.push({ name: 'login' });
        })
        .catch((_error) => {
          this.authError = _error;
          this.isAuthError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    const { token } = this.$route.params;

    this.resetPasswordGet({ token })
      .then((passwordRecovery) => {
        this.passwordRecovery = passwordRecovery;
      })
      .catch(() => {
        this.$router.push({ name: 'login' });
      });
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.auth-wrapper {
  width: 90%;
  max-width: 450px;
}

.card-header {
  background-color: var(--primary);
  text-align: center;
  padding: 20px;
}
</style>

<style>
#resetPasswordArea {
  margin: 0 !important;
  min-height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
}

#resetPasswordArea .card-title {
  background: transparent;
  margin: 0;
}
</style>
